@import "/srv/www/assets/styles/_mixins.scss";

.car-image {
    // no tailwind rule for this
    aspect-ratio: 2 / 1;
    inline-size: 100%;

    .icon {
        // tailwind sizes not big enough
        font-size: 20rem;
    }
}

.rims-row {
    scroll-snap-type: inline mandatory;

    & > * {
        scroll-snap-align: start;
    }
}
.add-to-cart-with-kitset.show-hover:hover::after {
    @apply text-base absolute block bg-gray-darker text-white opacity-95 w-40 p-1 bottom-full -right-8 z-20;
    content: attr(data-hover);
}
.add-to-cart.show-hover:hover::after {
    @apply text-base absolute block bg-gray-darker text-white opacity-95 w-40 p-1 bottom-full -right-10 z-20;
    content: attr(data-hover);
}

