<template>
    <div :id="`searcher_${componentId}`" class="relative w-full">
        <shop-input
            :id="`searcher_input_${componentId}`"
            v-model="displayValue"
            type="text"
            :placeholder="placeholder"
            :label="label"
            :autocomplete="false"
            border
            :required="required"
            :max-length="maxLength"
            :show-validity="showValidity"
            :valid="!isBlank(displayValue)"
            :invalid-message="invalidMessage"
            icon="marso-icon-search"
            @focusin="openSearcher"
            @keydown="searcherKeydown"
            @keyup.enter="selectOption"
            @focusout="onInputBlur"
        />

        <ul
            v-show="searchVisible"
            class="absolute left-0 z-10 w-full bg-white block shadow-sm"
            :class="customClass"
        >
            <template
                v-for="(option, index) in options.slice(0, 5)"
                :key="index"
            >
                <li
                    v-if="options"
                    class="p-2 hover:bg-gray cursor-pointer"
                    :class="{ active: activeOptionIndex === index }"
                    @click="itemSelected(option)"
                >
                    {{ option.label }}
                </li>
            </template>
            <li
                v-if="options.length > 5"
                class="bg-white cursor-default text-center p-2 border-t border-gray hover:bg-gray"
            >
                ...
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import debounce from "lodash/debounce";
import {
    getCurrentInstance,
    nextTick,
    onMounted,
    onUnmounted,
    PropType,
    Ref,
    ref,
    watch,
} from "vue";
import ShopInput from "../ShopInput.vue";
import AsyncSearchInputOption from "./AsyncSearchInputOption";
import { isBlank } from "../../../common/utils/validators";

const emit = defineEmits(["searchedValueChanged", "selected"]);

const props = defineProps({
    placeholder: {
        type: String,
        default: "",
    },
    label: {
        type: String,
        default: "",
    },
    displayValue: {
        type: String,
        default: "",
    },

    required: Boolean,
    maxLength: {
        type: Number,
        required: false,
    },
    customClass: {
        type: String,
        required: false,
        default: "",
    },
    debounceInMs: {
        type: Number,
        default: 300,
    },
    displayValueAfterSelection: {
        type: Boolean,
        default: false,
        required: false,
    },
    showValidity: {
        type: Boolean,
        default: false,
    },
    invalidMessage: {
        type: String,
        required: false,
        default: "",
    },

    options: {
        type: Array as PropType<Array<AsyncSearchInputOption>>,
        required: true,
        default: () => [],
    },
});

const displayValue: Ref<string> = ref(props.displayValue);
const searchVisible: Ref<boolean> = ref(false);
const componentId = getCurrentInstance()?.uid;
const activeOptionIndex: Ref<number> = ref(-1);
const selectedValue = ref(displayValue.value);
let searcher: HTMLElement | null = null;
let searcherInput: HTMLElement | null = null;

const openSearcher = () => {
    searchVisible.value = true;
    selectedValue.value = displayValue.value;

    nextTick(() => {
        searcherInput = document.getElementById(
            `searcher_input_${componentId}`
        );

        if (searcherInput === null) {
            return;
        }

        searcherInput.focus();
    });
};

const clickOutsideListener = (event: MouseEvent) => {
    if (searcher !== null && !searcher.contains(event.target as HTMLElement)) {
        searchVisible.value = false;
        return;
    }
};

onMounted(() => {
    searcher = document.getElementById(`searcher_${componentId}`);
    document.addEventListener("click", clickOutsideListener);
});

onUnmounted(() => {
    document.removeEventListener("click", clickOutsideListener);
});

const onInputBlur = () => {
    if (selectedValue.value !== displayValue.value) {
        displayValue.value = "";
    }
};

watch(
    displayValue,
    debounce((value) => {
        if (value === "") {
            return;
        }

        emit("searchedValueChanged", displayValue.value);
    }, props.debounceInMs)
);

const itemSelected = (item: AsyncSearchInputOption) => {
    displayValue.value = props.displayValueAfterSelection
        ? item.value
        : item.label;

    selectedValue.value = displayValue.value;

    emit("selected", item);
};

const setDisplayValue = (value: string) => {
    displayValue.value = value;
};

const searcherKeydown = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
        searchVisible.value = false;
    } else if (event.key === "ArrowDown") {
        navigateOptions(1);
    } else if (event.key === "ArrowUp") {
        navigateOptions(-1);
    } else if (event.key === "Enter") {
        event.preventDefault();
    }
};

const selectOption = () => {
    if (activeOptionIndex.value !== -1) {
        itemSelected(props.options[activeOptionIndex.value]);
        searchVisible.value = false;
    }
};

const navigateOptions = (step: number) => {
    const newIndex = activeOptionIndex.value + step;

    if (newIndex >= 0 && newIndex < props.options.length) {
        activeOptionIndex.value = newIndex;
    }
};

defineExpose({ setDisplayValue });
</script>

<style scoped>
.active {
    background-color: #e5e5e5;
}
</style>
