
.fade-enter-active,
.fade-leave-active {
    @apply transition-opacity duration-300;
}

.fade-enter-from,
.fade-leave-to {
    @apply opacity-0;
}

div {
    &.loading {
        @apply relative overflow-hidden;

        > * {
            @apply filter blur-sm;
        }

        &::after {
            @apply block absolute left-0 top-0 w-full h-full bg-gray-darkest opacity-10;

            content: " ";
        }
    }
}

.btn {
    @apply flex flex-row items-center justify-center gap-1 transition-all duration-300 outline-none
    py-1 px-2 border font-bold uppercase text-base h-[50px] p-[20px];

    &.btn-secondary:not(:disabled) {
        @apply border-gray-dark border-opacity-50 bg-gray-dark hover:bg-gray-darker text-white hover:text-white focus:ring-gray-dark;

        &.btn-outline {
            @apply hover:bg-gray-dark text-gray-darkest;
        }
    }

    &.btn-primary:not(:disabled) {
        @apply border-blue bg-blue hover:bg-blue-dark text-white focus:ring-blue;

        &.btn-outline {
            @apply hover:bg-blue text-blue hover:text-white;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-blue hover:text-blue-light;
        }
    }

    &.btn-success:not(:disabled) {
        @apply border-green bg-green hover:bg-green-dark text-white focus:ring-green;

        &.btn-outline {
            @apply hover:bg-green text-green hover:text-white;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-blue hover:text-blue-light;
        }
    }

    &.btn-danger:not(:disabled) {
        @apply border-red bg-red hover:bg-red-dark text-white focus:ring-red;

        &.btn-outline {
            @apply hover:bg-red text-red hover:text-white;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-red hover:text-red-light;
        }
    }

    &.btn-warning:not(:disabled) {
        @apply border-orange bg-orange hover:bg-orange-dark text-gray-darkest hover:text-white focus:ring-orange;

        &.btn-outline {
            @apply hover:bg-orange-light text-orange hover:text-gray-darkest;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-orange hover:text-orange-light;
        }
    }

    &.btn-info:not(:disabled) {
        @apply border-blue-light bg-blue-light hover:bg-blue text-white focus:ring-blue-light;

        &.btn-outline {
            @apply hover:bg-blue-light text-blue-light hover:text-gray-darkest;
        }

        &.btn-link {
            @apply bg-transparent border-transparent text-blue-light hover:text-blue-dark;
        }
    }

    &.btn-link {
        @apply border-transparent bg-transparent;

        &:not(:disabled) {
            @apply text-blue hover:text-blue-dark;
        }
    }

    &.btn-outline,
    &.btn-outline:not(:disabled) {
        @apply border-2 bg-white;
    }

    &.btn-neutral {
        @apply border-gray bg-gray hover:bg-gray-dark hover:border-gray-dark text-gray-darkest focus:ring-gray;
    }

    &.btn-cart {
        @apply rounded-none text-2xl p-1 px-5 h-full;
    }

    &.btn-rounded {
        @apply rounded;
    }

    &:disabled {
        @apply opacity-50;
    }
}

.btn-group {
    @apply flex flex-row items-center justify-center gap-0;

    .btn:first-child {
        @apply rounded-r-none;
    }

    .btn:last-child {
        @apply rounded-l-none;
    }
}

.text-shadow-white {
    text-shadow: 0 0 3px #fff;
}

.text-shadow-black {
    text-shadow: 0 0 3px #000;
}

.filters {
    @apply bg-white border-b-2 border-gray-dark p-4 flex flex-col lg:flex-row lg:text-sm gap-2;
}

.additional-filters {
    @apply bg-white border-b-2 border-gray-dark grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-7 p-4 gap-2 lg:text-sm;
}

.filters,
.additional-filters,
.additional-filters-close-btn {
    @apply duration-300 ease-out transition-all;

    &.closed {
        @apply h-0 py-0 overflow-hidden;
    }
}

.input-group {
    @apply flex flex-row gap-0 justify-center items-stretch content-center w-full;

    > :not(:last-child) {
        @apply rounded-r-none;

        input {
            @apply rounded-r-none;
        }
    }

    > :not(:first-child) {
        @apply rounded-l-none;

        input {
            @apply rounded-l-none;
        }
    }
}

.discount-theme {
    border-top: 0;
    background-color: #686868;
    color: #fff;
    //box-shadow: rgba(104, 104, 104, 0.4) 0px -5px, rgba(104, 104, 104, 0.3) 0px -10px, rgba(104, 104, 104, 0.2) 0px -15px, rgba(104, 104, 104, 0.1) 0px -20px, rgba(104, 104, 104, 0.05) 0px -25px;
    > td {
        grid: none !important;
    }
    &.discount-theme-0 {
        background-color: #686868;
    }
    &.discount-theme-1 {
        background-color: #20c25b;
        //box-shadow: rgba(32, 194, 91, 0.4) 0px -5px, rgba(32, 194, 91, 0.3) 0px -10px, rgba(32, 194, 91, 0.2) 0px -15px, rgba(32, 194, 91, 0.1) 0px -20px, rgba(32, 194, 91, 0.05) 0px -25px;
    }
    &.discount-theme-2 {
        background-color: #ffbb00;
        //box-shadow: rgba(255, 187, 0, 0.4) 0px -5px, rgba(255, 187, 0, 0.3) 0px -10px, rgba(255, 187, 0, 0.2) 0px -15px, rgba(255, 187, 0, 0.1) 0px -20px, rgba(255, 187, 0, 0.05) 0px -25px;
    }
    &.discount-theme-3 {
        background-color: #ff5e66;
        //box-shadow: rgba(255, 94, 102, 0.4) 0px -5px, rgba(255, 94, 102, 0.3) 0px -10px, rgba(255, 94, 102, 0.2) 0px -15px, rgba(255, 94, 102, 0.1) 0px -20px, rgba(255, 94, 102, 0.05) 0px -25px;
    }
}

.button-orderable.active::after,
.button-limited.active::after {
    @apply absolute block bg-gray-darker text-white opacity-95 w-80 p-4 -bottom-8 -right-8 -z--1;
    content: var(--tooltip-limited);
}
.button-orderable.active::after {
    content: var(--tooltip-orderable);
}
.tooltip.marso-icon-recycle:hover::after {
    @apply font-sans absolute block bg-gray-darker text-white font-bold opacity-80 px-2 py-1 whitespace-nowrap -left-6 -top-full -z--1 rounded;
    content: var(--tooltip-recycle);
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
  
.tooltip .tooltip-content {
    @apply font-sans absolute block bg-gray-darker text-white font-bold opacity-80 px-2 py-1 whitespace-nowrap -left-6 -top-full -z--1 rounded;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
    z-index: 1;
}

.tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

@media (pointer: fine) {
    .button-orderable > .tooltip:hover::after,
    .button-limited > .tooltip:hover::after {
        @apply absolute block bg-gray-darker text-white opacity-95 w-80 p-4 -bottom-8 -right-8 -z--1;
        content: var(--tooltip-limited);
    }
    .button-orderable > .tooltip:hover::after {
        content: var(--tooltip-orderable);
    }
}

.textbox {
    @apply relative cursor-pointer
}

.textbox:before {
    content: attr(data-text); /* here's the magic */
    @apply bg-gray-darker text-white opacity-95 p-4 absolute text-center hidden left-full top-1/2
    -translate-y-1/2 whitespace-pre;
}

.textbox:hover:before {
    @apply block
}

.textbox-left:before {
    @apply right-full mr-2;
    left:initial;
    margin:initial;
}

.textbox-right:before {
    @apply left-full ml-2;
    right:initial;
    margin:initial;
}

.coupon-state {
    .active_waiting {
        @apply bg-purple-500;
    }

    .active_new {
        @apply bg-green;
    }

    .active_partially_used {
        @apply bg-blue;
    }

    .archived_full_used {
        @apply bg-gray-darker;
    }

    .archived_partially_used {
        @apply bg-orange;
    }

    .archived_not_used {
        @apply bg-red;
    }
}

.foreign-customer {
    .hide-foreign-customer {
        display: none;
    }
}
#products-panel {
    table thead {
        top: 56px;
        position: sticky;
        z-index: 1;
    }
}
@media print
{
    html, body {
        height:100%;
        overflow: hidden;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }

    .print-width
    {
        width: 100% !important;
    }
    .print-height
    {
        height: 100% !important;
    }
    .print-margin-0
    {
        margin: 0 !important;
    }
    .print-padding-0
    {
        padding: 0 !important;
    }
    .print-margin
    {
        margin: 20px !important;
    }
    .print-padding
    {
        padding: 20px !important;
    }
    .print-text-sm
    {
        font-size: small;
    }
}

;@import "sass-embedded-legacy-load-done:9";