@import "/srv/www/assets/styles/_mixins.scss";

$navHeight: 3.5rem;
$footerHeight: 7.375rem;
$footerHeightMobileLoginPage: 13.25rem;

.app-content {
    min-height: calc(100vh - $navHeight - $footerHeight);
}

.app-content-login {
    min-height: calc(100vh - $footerHeight);
    padding-bottom: 50px;
}

@media screen and (max-width: 1024px) {
    .app-content-login {
        min-height: calc(100vh - $footerHeightMobileLoginPage);
        padding-bottom: 50px;
    }
}
