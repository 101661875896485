@import "/srv/www/assets/styles/_mixins.scss";

.tag-search-input {
    @apply relative grow;

    ul.search-dropdown {
        @apply absolute left-0 z-10 w-full bg-white block shadow-sm;

        li {
            @apply p-2 border-t border-gray cursor-pointer hover:bg-gray;

            &:first-child {
                @apply border-0;
            }

            &.ellipsis {
                @apply bg-white cursor-default text-center;
            }
        }
    }

    ul.tag-list {
        @apply flex flex-row gap-1;

        li {
            @apply whitespace-nowrap bg-gray rounded p-1 flex flex-row items-center gap-1 cursor-pointer;
        }
    }
}
