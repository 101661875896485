<template>
    <tyre-filter
        :searchLabel="
            trans('truck_tyre_search', 'Truck tyre search', 'truck_tyres')
        "
        :form="form"
        :placeholder="
            trans(
                'tag_search_input_placeholder',
                'eg. 29580225 continental',
                'truck_tyres'
            )
        "
        category="truckTyre"
        ref="tyreFilterRef"
        hasAdditionalFilters
        :listFields="truckTyreProductListFields"
    >
        <template #filtersPrepend>
            <dropdown-input
                :placeholder="trans('tyre_type', 'Tyre type', 'truck_tyres')"
                :items="getTyreTypeItems()"
                v-model="tyreTypeSelect"
                disableDeselect
            />
        </template>

        <template #additionalFilters>
            <toggle
                class="p-2 bg-gray justify-between"
                :label="trans('bus', 'Bus', 'truck_tyres')"
                v-model="busSelect"
            />
            <toggle
                class="p-2 bg-gray justify-between"
                :label="trans('steered', 'Steered', 'truck_tyres')"
                v-model="steeredSelect"
            />
            <toggle
                class="p-2 bg-gray justify-between"
                :label="trans('traction', 'Traction', 'truck_tyres')"
                v-model="tractionSelect"
            />
            <toggle
                class="p-2 bg-gray justify-between"
                :label="trans('trailer', 'Trailer', 'truck_tyres')"
                v-model="trailerSelect"
            />
            <toggle
                class="p-2 bg-gray justify-between"
                :label="trans('universal', 'Universal', 'truck_tyres')"
                v-model="universalSelect"
            />
        </template>
    </tyre-filter>
</template>

<script setup lang="ts">
import { computed, ComputedRef, Ref, ref, watch } from "vue";
import DropdownInput from "../../components/Form/DropdownInput/DropdownInput.vue";
import {
    BrandValue,
    ProductFilterRequestObject,
} from "../../models/ProductFilter";
import TyreFilter from "../../components/ProductSearch/TyreFilter.vue";
import {
    truckTyreSearchForm,
    truckTyreProductListFields,
    getQueryParams,
} from "../../components/ProductSearch/tyre-search";
import { Toggle } from "../../components/Form";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import DropdownInputItem from "../../components/Form/DropdownInput/DropdownInputItem";
import { trans } from "../../common/i18n";
import { ShopConfig } from "../../common/utils/ShopConfig";

const store = useStore();
const route = useRoute();
const tyreFilterRef: Ref<null | typeof TyreFilter> = ref(null);

const filterApplicationNotationValues: ComputedRef<string[]> = computed(() => {
    return store.getters[
        "product/filter/truckTyre/filterValues"
    ].applicationNotation.values.filter(
        (value: string | number | BrandValue) => {
            return typeof value === "string";
        }
    );
});

const tyreTypeSelect: Ref<string> = ref("122");

const getTyreTypeItems = (): DropdownInputItem[] => {
    let tyreTypeItems = [
        new DropdownInputItem(
            "122",
            `<i class="marso-icon-tomlo_nelkul"></i> ${trans(
                "tubeless_tyre",
                "Tubeless tyre",
                "truck_tyres"
            )}`
        ),
        new DropdownInputItem(
            "120",
            `<i class="marso-icon-coll"></i> ${trans(
                "20_inches_tyre",
                "20 inches tyre",
                "truck_tyres"
            )}`
        ),
    ];

    if ('SRB' != ShopConfig.getShopLocation()) {
        tyreTypeItems.push(
            new DropdownInputItem(
                "123|127",
                `<i class="marso-icon-retread"></i> ${trans("retreaded_tyre", "Retreaded tyre", "truck_tyres")}`
            )
        );
    }

    return tyreTypeItems;
};

const busSelect: Ref<boolean | undefined> = ref<boolean | undefined>(undefined);
const tractionSelect: Ref<boolean | undefined> = ref<boolean | undefined>(
    undefined
);
const steeredSelect: Ref<boolean | undefined> = ref<boolean | undefined>(
    undefined
);
const trailerSelect: Ref<boolean | undefined> = ref<boolean | undefined>(
    undefined
);
const universalSelect: Ref<boolean | undefined> = ref<boolean | undefined>(
    undefined
);

const form: Ref<ProductFilterRequestObject> = ref(
    Object.assign({}, truckTyreSearchForm)
);

const initQueryParams = getQueryParams(form.value, route);
for (const key in initQueryParams) {
    if (initQueryParams.hasOwnProperty(key)) {
        if (key === "axCategories") {
            if (typeof initQueryParams[key] === "object") {
                tyreTypeSelect.value = `${initQueryParams[key][0]}`;
            } else {
                tyreTypeSelect.value = `${initQueryParams[key]}`;
            }
            form.value['axCategory'] = initQueryParams[key];
        }
        if (key === "applicationNotation") {
            for (const notation of initQueryParams[key]) {
                if (notation === "BUSZ") {
                    busSelect.value = true;
                }
                if (notation === "HÚZÓ") {
                    tractionSelect.value = true;
                }
                if (notation === "KORM") {
                    steeredSelect.value = true;
                }
                if (notation === "PÓTK") {
                    trailerSelect.value = true;
                }
                if (notation === "UNIV") {
                    universalSelect.value = true;
                }
            }
        }
        form.value[key] = initQueryParams[key];
    }
}

watch(
    () => tyreTypeSelect.value,
    (selectedValue) => {
        for (const key in form.value) {
            if (!form.value.hasOwnProperty(key)) {
                continue;
            }

            if (key === "axCategory") {
                if (selectedValue.includes('|')) {
                    const tyreCategories = selectedValue.split('|');
                    form.value[key] = tyreCategories;
                } else {
                    form.value[key] = [selectedValue];
                }

                busSelect.value = false;
                tractionSelect.value = false;
                steeredSelect.value = false;
                trailerSelect.value = false;
                universalSelect.value = false;

                continue;
            }

            if (key === "type") {
                continue;
            }

            form.value[key] = null;
        }
    }
);

const applicationNotationValues = computed<string[]>(() => {
    const ret: string[] = [];

    if (busSelect.value) {
        ret.push("BUSZ");
    }
    if (tractionSelect.value) {
        ret.push("HÚZÓ");
    }
    if (steeredSelect.value) {
        ret.push("KORM");
    }
    if (trailerSelect.value) {
        ret.push("PÓTK");
    }
    if (universalSelect.value) {
        ret.push("UNIV");
    }

    return ret;
});

watch(
    () => applicationNotationValues.value,
    (applicationNotation) =>
        (form.value.applicationNotation = applicationNotation),
    { deep: true }
);
</script>

<style>
    #dropdown_items_213 {
        display: none;
    }
</style>
