@import "/srv/www/assets/styles/_mixins.scss";

@media print {
    html,
    body {
        height: 100%;
        overflow: hidden;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    .print-width {
        width: 100% !important;
    }
    .print-height {
        height: 100% !important;
    }
    .print-margin {
        margin: 0 !important;
    }
    .print-padding {
        padding: 0 !important;
    }
}
