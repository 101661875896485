@import "/srv/www/assets/styles/_mixins.scss";

.modal-loading {
    .icon {
        // tailwind sizes not big enough
        font-size: 10rem;
        animation: spin 3s linear infinite;
        display: block;
    }
}

.load-capacity-table {
    // simplified css
    @apply min-w-full text-right border-separate border-spacing-0;
    td,
    th {
        @apply p-1 px-2;
    }
    .unit {
        @apply text-gray-darker;
    }
    tfoot {
        @apply italic border-t-2 border-gray-dark;
    }
    thead {
        @apply border-b-2 border-gray-dark;
    }
    thead th {
        @apply border-r border-b-2 border-gray-dark;
        &:first-child {
            @apply border-r-2 left-0 bg-white;
            position: sticky;
        }
    }

    tbody {
        tr:first-child {
            @apply border-0;
        }
        tr:nth-child(even) {
            @apply bg-gray;
        }
        tr {
            @apply border-t border-gray-dark;
            th {
                @apply border-r-2 border-b-1 border-gray-dark left-0 bg-white;
                position: sticky;
            }
            td {
                @apply border-r border-b-1 border-gray-dark;
            }
        }
    }
}
