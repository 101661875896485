@import "/srv/www/assets/styles/_mixins.scss";

[data-component="dashboard"] {
    .flash-search-links-grid {
        @apply grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 uppercase;

        .flash-link {
            @apply relative overflow-hidden flex justify-between items-center gap-2 text-gray-darkest;
            @apply hover:bg-gray-darkest hover:text-white transition-colors bg-white text-xs xl:text-base border-0 border-l-6;
            @apply border-red py-4 px-2 font-bold cursor-pointer;

            span,
            i {
                @apply relative;
            }

            i {
                @apply text-gray-darkest;
            }

            &:hover {
                i {
                    @apply text-white;
                }
            }
        }
    }
}
