@import "/srv/www/assets/styles/_mixins.scss";

@media print {
    .print-mt-4 {
        margin-top: -2rem;
    }
    .print-p {
        padding: -1rem;
        padding-bottom: 1rem;
    }
}
.invoice-filter-input {
    > div > input {
        height: 48px;
    }
}
