@import "/srv/www/assets/styles/_mixins.scss";

@import "../../../node_modules/@vueup/vue-quill/dist/vue-quill.snow.css";

.ql-editor {
    padding: 0;
    min-height: auto;
    margin-bottom: 20px;

    img {
        display: inline;
    }
}
